import React, { memo } from 'react'
import {
  GlobalStyles as GlobalStylesBase,
  CssBaseline,
  useTheme,
} from '@mui/material'

// styles
import bodyText from '@styles/bodyText'

const GlobalStyles = () => {
  const theme = useTheme()
  return (
    <>
      <CssBaseline />
      <GlobalStylesBase
        styles={{
          html: {
            backgroundColor: 'transparent',
            fontSize: '62.5%;',
            ul: {
              margin: 0,
              padding: 0,
            },
            li: {
              margin: 0,
              padding: 0,
            },
            a: {
              color: 'inherit',
              textDecoration: 'none',
              transition: theme.transitions.create(['color']),
            },
            ['a:hover']: {
              color: theme.palette.primary.main,
            },
            body: {
              backgroundColor: 'transparent',
              ...bodyText(),
            },
            ['@font-face']: {
              fontFamily: 'Bastliga One',
              fontWeight: 400,
              fontDisplay: 'swap',
              fontStyle: 'normal',
              fontNamedInstance: 'Regular',
              src: 'url(/fonts/bastliga-one.woff2) format("woff2")',
            },
          },
        }}
      />
    </>
  )
}

export default memo(GlobalStyles)
