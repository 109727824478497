import { useTheme } from '@mui/material'

const bodyText = () => {
  const theme = useTheme()
  const styles: React.CSSProperties = {
    fontSize: '1.6rem',
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem',
    },
  }
  return styles
}

export default bodyText
