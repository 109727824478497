// npm
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    fontFamilyHand: string
    smHeading: React.CSSProperties
    xlHeading: React.CSSProperties
    smBody: React.CSSProperties
    lgBody: React.CSSProperties
    signature: React.CSSProperties
    quote: React.CSSProperties
  }
  interface TypographyOptions {
    fontFamilyHand?: string
    smHeading?: React.CSSProperties
    xlHeading?: React.CSSProperties
    smBody?: React.CSSProperties
    lgBody?: React.CSSProperties
    signature?: React.CSSProperties
    quote?: React.CSSProperties
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    fontFamilyHand: true
    smHeading: true
    xlHeading: true
    smBody: true
    lgBody: true
    signature: true
    quote: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    secondaryDarker: string
    outline: string
  }
  interface PaletteOptions {
    secondaryDarker?: string
    outline?: string
  }
}

const fonts = {
  sans: "'Poppins', sans-serif",
  hand: "'Bastliga One', cursive",
}

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
}

const colors = {
  primary: '#dd4242',
  secondary: '#191c26',
  secondaryDark: '#111319',
  secondaryDarker: '#080a12',
  textPrimary: '#a9afc3',
  outline: '#3a3f50',
}

const headingBase = {
  fontWeight: fontWeights.bold,
  lineHeight: 1.1,
}

let theme = createTheme({
  spacing: 10,
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      dark: colors.secondaryDark,
    },
    secondaryDarker: colors.secondaryDarker,
    outline: colors.outline,
    text: {
      primary: colors.textPrimary,
    },
    error: {
      main: colors.primary,
    },
    success: {
      main: '#73f294',
    },
  },
  typography: {
    fontFamily: fonts.sans,
    fontFamilyHand: fonts.hand,
    htmlFontSize: 10,
    fontSize: 16,
    fontWeightLight: fontWeights.light,
    fontWeightRegular: fontWeights.regular,
    fontWeightBold: fontWeights.bold,
    xlHeading: {
      ...headingBase,
    },
    h1: {
      ...headingBase,
    },
    h2: {
      ...headingBase,
      lineHeight: 1.3,
    },
    h3: {
      ...headingBase,
    },
    h4: {
      ...headingBase,
      fontSize: '2.6rem',
      fontWeight: fontWeights.medium,
    },
    h5: {
      fontSize: '2rem',
      fontWeight: fontWeights.medium,
    },
    quote: {
      fontSize: '2rem',
    },
    smHeading: {
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      fontWeight: fontWeights.regular,
      letterSpacing: '0.08em',
    },
    smBody: {
      fontSize: '1.6rem',
    },
    lgBody: {},
    signature: {
      fontFamily: fonts.hand,
      fontSize: '6rem',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {},
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {},
      },
    },
  },
})

// Annoyingly we cant use ...bodyText() styles here so have to manually duplicate
theme.components.MuiInputBase.styleOverrides.input = {
  fontSize: '1.7rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
}

// Annoyingly we cant use ...bodyText() styles here so have to manually duplicate
theme.components.MuiFormLabel.styleOverrides.root = {
  fontSize: '1.7rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
}

theme.typography.xlHeading = {
  ...theme.typography.xlHeading,
  fontSize: '6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '10rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '11rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '12rem',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '4.8rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '5.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '5.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '6rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '6.4rem',
  },
}

theme.typography.lgBody = {
  ...theme.typography.lgBody,
  fontSize: '1.8rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.2rem',
  },
}

export default theme
